import { clamp } from './clamp';

/*
 * Get the percentage scrolled over an element
 * @param {HTMLElement} element
 * @return {number} value between 0 and 1
 */
export function scrolledOverPercent(element) {
  const scrolled = window.pageYOffset;
  const height = element.offsetHeight;
  const top = element.offsetTop;
  const percent = scrolled / (top + height);

  return clamp(percent);
}
