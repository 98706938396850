/*
 * Resize to original aspect ratio
 * @param {Object} dimensions The original dimensions
 * @param {number} dimensions.height
 * @param {number} dimensions.width
 * @param {string} prop Property name
 * @param {number} value Current property value
 * @param {Object} new height and width
 */
export function resizeToRatio(dimensions, prop, value) {
  const otherProp = prop === 'width' ? 'height' : 'width';
  const otherPropValue = Math.round((value * dimensions[otherProp]) / dimensions[prop]);

  return {
    [otherProp]: otherPropValue,
    [prop]: value
  };
}
