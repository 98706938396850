import React from 'react';
import SEO from 'components/seo';
import { TopHeader } from 'layouts/top-header';
import { LazyHero } from 'components/lazy-hero';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import Color from 'color';

const HeroPanel = styled('div')`
  padding: 1.5rem;
  background-color: ${Color('#fff')
    .alpha(0.3)
    .rgb()
    .string()};
`;

function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "rugby-673461.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      logo: file(relativePath: { eq: "rugby-world-titans.png" }) {
        childImageSharp {
          fixed(height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <TopHeader>
      <SEO title="Home" />
      <LazyHero imageSrc={data.desktop.childImageSharp.fluid.src} minHeight="100%">
        <HeroPanel>
          <Img fixed={data.logo.childImageSharp.fixed} alt="Rugby World Titans" />
          <h1>Coming Soon</h1>
          <h2>More than just a sports brand!</h2>
        </HeroPanel>
      </LazyHero>
    </TopHeader>
  );
}

export default IndexPage;
