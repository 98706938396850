import { resizeToRatio } from './resizeToRatio';

/*
 * Simulate "background-position: contain"
 * @param {Object} dimensions The original dimensions
 * @param {number} dimensions.height
 * @param {number} dimensions.width
 * @param {Object} maxDimensions The available space
 * @param {number} maxDimensions.height
 * @param {number} maxDimensions.width
 * @return {Object} new height and width
 */
export function resizeToContain(dimensions, maxDimensions) {
  return Object.keys(dimensions).reduce(
    (prevDimensions, prop) =>
      prevDimensions[prop] > maxDimensions[prop]
        ? resizeToRatio(prevDimensions, prop, maxDimensions[prop])
        : prevDimensions,
    dimensions
  );
}
