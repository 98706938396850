import React, { useEffect, useState, ReactNode, SFC, ReactElement } from 'react';
import Color from 'color';
import styled from '@emotion/styled';
import { useInView } from 'react-hook-inview';
import { resizeToCover } from 'utils/resizeToCover';
import { scrolledOverPercent } from 'utils/scrolledOverPercent';

const Cover: SFC<unknown> = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Root: SFC<any> = styled.div`
  position: relative;
  min-height: ${(props: any): string => props.minHeight};
  overflow: hidden;
`;

const Img: SFC<any> = styled(Cover)`
  background-attachment: ${(props: any): string => (props.isFixed ? 'fixed' : 'scroll')};
  background-image: url(${(props: any): string => props.imageSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(props: any): string =>
    props.imageWidth ? `${props.imageWidth}px ${props.imageHeight}px` : 'cover'};
  opacity: ${(props: any): number => (props.isVisible ? 1 : 0)};
  transition-duration: ${(props: any): string => `${props.transitionDuration}ms`};
  transition-property: opacity;
  transition-timing-function: ${(props: any): string => props.transitionTimingFunction};
  filter: blur(8px);
  transform: scale(1.1);
`;

const Overlay: SFC<any> = styled(Cover)`
  display: flex;
  justify-content: ${(props: any): string => (props.isCentered ? 'center' : 'flex-start')};
  align-items: ${(props: any): string => (props.isCentered ? 'center' : 'stretch')};
  text-align: ${(props: any): string => (props.isCentered ? 'center' : 'left')};
  background-color: ${(props: any): string =>
    Color(props.color)
      .alpha(props.opacity)
      .rgb()
      .string()};
`;

interface Props {
  children: ReactNode | ReactNode[];
  className?: string;
  color?: string;
  imageSrc: string;
  isCentered?: boolean;
  isFixed?: boolean;
  minHeight?: string;
  opacity?: number;
  parallaxOffset?: number;
  transitionDuration?: number;
  transitionTimingFunction?: string;
}

interface State {
  readonly backgroundPositionY: string;
  readonly backgroundDimensions: any;
  readonly heroDimensions: any;
  readonly image: HTMLImageElement | null;
}

export function LazyHero({
  children,
  imageSrc,
  parallaxOffset = 0,
  className,
  isFixed = false,
  isCentered = true,
  color = '#001442',
  minHeight = '50vh',
  opacity = 0.5,
  transitionDuration = 600,
  transitionTimingFunction = 'ease-in-out'
}: Props): ReactElement<Props> {
  const [ref, isVisible]: any = useInView(),
    [state, setState] = useState<State>({
      backgroundPositionY: 'center',
      backgroundDimensions: null,
      heroDimensions: null,
      image: null
    }),
    updateSize = () => {
      if (!state.image) return;

      const heroDimensions = {
        height: ref.offsetHeight,
        width: ref.offsetWidth
      };

      const imageDimensions = {
        height: state.image.height,
        width: state.image.width
      };

      const resizedImage = resizeToCover(imageDimensions, heroDimensions);
      const initialVisibleImageHeight = resizedImage.height - parallaxOffset;

      const height =
        initialVisibleImageHeight < heroDimensions.height
          ? resizedImage.height + heroDimensions.height - initialVisibleImageHeight
          : resizedImage.height;

      const finalHeight = height + ref.offsetTop * 2;

      const backgroundDimensions = resizeToCover(imageDimensions, { height: finalHeight });
      setState({ ...state, backgroundDimensions, heroDimensions });
    },
    updatePosition = () => {
      if (!state.backgroundDimensions) return;
      const position =
        0 +
        ref.offsetTop -
        // Center image vertically
        state.backgroundDimensions.height / 2 +
        state.heroDimensions.height / 2 -
        parallaxOffset / 2 +
        // Apply scroll position
        parallaxOffset * scrolledOverPercent(ref);

      setState({ ...state, backgroundPositionY: `${Math.round(position)}px` });
    },
    loadImage = () => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        setState({ ...state, image });

        if (parallaxOffset > 0) {
          updateSize();
          updatePosition();
        }
      };
    },
    handleScroll = () => {
      updatePosition();
    },
    handleResize = () => {
      updateSize();
      updatePosition();
    };

  useEffect((): void | (() => void) => {
    loadImage();

    if (parallaxOffset > 0) {
      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }

    return undefined;
  }, []);

  return (
    <Root className={className} ref={ref} minHeight={minHeight}>
      <Img
        isVisible={state.image && isVisible}
        isFixed={isFixed || parallaxOffset > 0}
        imageHeight={state.backgroundDimensions && state.backgroundDimensions.height}
        imageSrc={imageSrc}
        imageWidth={state.backgroundDimensions && state.backgroundDimensions.width}
        style={{ backgroundPositionY: state.backgroundPositionY }}
        transitionDuration={transitionDuration}
        transitionTimingFunction={transitionTimingFunction}
      />
      <Overlay color={color} isCentered={isCentered} opacity={opacity}>
        {children && <div>{children}</div>}
      </Overlay>
    </Root>
  );
}
